import React from "react"

import Layout from "../components/common/layout/layout"
import Seo from "../components/common/layout/seo"

import SIBAboutPage from "../components/frontend/aboutpage/about"

const AboutPage = ({ data }) => {
  return (
    <Layout showFrontendLinks={true}>
      <Seo title="Shakespeare In Bits - About Us" />
      <SIBAboutPage/>
    </Layout>
  )
}

export default AboutPage