import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const SIBAboutPage = () => {
  return (
    <>
      <section className="page-header bg-paper bg-tear-white">
        <div className="container">
          <div className="columns">
            <div className="column is-8-desktop is-offset-2-desktop">
              <h1>Learning made easy</h1>
              <p>Using technology to deliver innovative learning solutions</p>
            </div>
          </div>
        </div>
      </section>

      <section className="section bg-tear-yellow">
        <div className="container">
          <div className="columns">
            <div className="column is-8 is-offset-2">
              <div className="content pb-4">
                <h2 className="heading-large">Prologue</h2>
                <p>
                  Shakespeare In Bits was first released in 2009 by boutique
                  Irish edu-tech company, Mindconnex Learning.{" "}
                </p>
                <p>
                  Founders Michael Cordner and Fergus Connolly had previously
                  worked for an educational publisher developing products for
                  the nascent digital learning market and had witnessed the many
                  challenges facing educators. Traditional approaches, centred
                  on print-based educational materials, were being upended at a
                  ferocious pace by pressure from all directions to introduce
                  technology into the learning environment. Meanwhile, debate
                  raged about the efficacy of such technology and how best to
                  integrate it.
                </p>
                <p>
                  So, along with a talented team of educators, writers, and
                  animators, we set out to build resources that would show how
                  technology could deliver solutions rather than problems.
                </p>
                <h2 className="heading-large">Enter William Shakespeare</h2>
                <p>
                  Then came the practical challenge of content. Enter William
                  Shakespeare! We believed that Shakespeare’s plays were
                  particularly suited to an innovative digital treatment
                  that could harness the many advantages of digital technology.
                  After all, they are not simply texts to be read but scripts
                  for dramas to be watched and heard too. Our goal would be to
                  retain academically robust texts but deliver them in a form
                  that would appeal to an audience being increasingly seduced by
                  rich and entertaining digital media content.{" "}
                </p>
                <p>
                  So was born Shakespeare In Bits, an all-in-one package,
                  presented in a way that enables contemporary students to read,
                  digest, understand and appreciate the text in bite-sized
                  chunks or "bits" both in a personalized or class setting.
                </p>
                <StaticImage
                  className="image is-rounded mt-6"
                  src="../../../scss/img/about-us.png"
                  alt="Placeholder"
                />
                <h2 className="heading-large">
                  A digital learning resource with real outcomes
                </h2>
                <p>
                  Today Shakespeare In Bits has been embraced by hundreds of
                  schools and thousands of individual users, as an effective
                  teaching and learning tool. Feedback from our many users is
                  that Shakespeare In Bits succeeds in introducing the Bard’s
                  most popular plays to a generation of technology-confident
                  students.
                </p>
                <p>
                  With five titles currently — Romeo and Juliet, Macbeth, A
                  Midsummer Night’s Dream, Hamlet and Julius Caesar —
                  Shakespeare In Bits provides an easier, more digestible way of
                  tackling Shakespeare, with real outcomes.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section bg-yellow footer-margin">
        <div className="container">
          <div className="columns">
            <div className="column is-8 is-offset-2">
              <div className="content">
                <h2 className="heading-large">“O brave new world”…</h2>
                <p>
                  The seismic shift in the way educational materials are
                  developed and distributed, continues apace. Sophisticated
                  personal electronic devices are ubiquitous. Web speed and
                  access are increasing all the time. So, consumption and
                  delivery of educational content must continue to adapt.
                </p>
                <p>
                  In this context, the most efficient and economical way to
                  maintain and upgrade our product, with minimum disruption to
                  users, is via a cloud-based platform. Therefore, Shakespeare
                  In Bits will be available exclusively as an online
                  subscription service going forward. This will allow us to
                  deliver a consistent end-user experience, administer the
                  service efficiently and provide the resources cost-effectively
                  to you, the user.
                </p>
                <p>
                  To all our customers, thank you for your continued support for
                  Shakespeare In Bits. The overwhelmingly positive feedback we
                  have received to date has been rewarding and encouraging. We
                  look forward to expanding and upgrading the product further in
                  the coming years to make it an even more indispensable
                  resource for the study of Shakespeare’s great plays!
                </p>
                <p>Your Humble Servants,</p>
                <p className="signature">The Shakespeare In Bits Team</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default SIBAboutPage
